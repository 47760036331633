<template>
    <div class="modal-edit-style-type items-start bg-main">
        <!-- <div class="header" @click="submit">
            <i class="material-icons m-16">chevron_left</i>
        </div> -->
        <div class="header">
            <i class="material-icons m-16" @click="submit">chevron_left</i>
            <div v-if="$isAndroid()" @click="submit" class="edit-profile-complete m-r-4">저장</div>
        </div>
        <div class="body" ref="body" v-if="items.length">
            <div class="title m-l-20 f-medium">
                {{ $translate(`MODAL_${options.key.toUpperCase()}_TYPE`) }}
                <div
                    v-if="multiSelect && options.isUsingManager !== 'manager_active'"
                    class="text f-12 c-grey-06 m-l-5"
                >
                    (중복 선택 가능)
                </div>
            </div>
            <ul class="description-area m-20">
                <li
                    v-if="getDescription"
                    class="description c-grey-07 f-14 m-20"
                    style="list-style-type: disc; color: #666b70"
                    v-html="getDescription"
                />
                <li
                    v-if="getDescriptionSecond"
                    class="description c-danger f-14 m-20"
                    style="list-style-type: disc; color: $c-danger"
                    v-html="getDescriptionSecond"
                />
            </ul>
            <div v-if="options.key === 'wealth'" class="types m-b-16" :class="{ 'grid-col': displayGrid }">
                <div class="subtitle">소득</div>
                <div
                    @click="select(item)"
                    class="item"
                    :class="{ selected: item.$$selected }"
                    :key="item.title"
                    v-for="item in income_types"
                >
                    <div class="item-title f-medium" v-html="item.title" />
                    <div v-if="item.desc" class="f-12 m-t-7" v-html="item.desc" />
                </div>

                <div class="subtitle">자산</div>
                <div
                    @click="select(item)"
                    class="item"
                    :class="{ selected: item.$$selected }"
                    :key="item.title"
                    v-for="item in asset_types"
                >
                    <div class="item-title f-medium" v-html="item.title" />
                    <div v-if="item.desc" class="f-12 m-t-7" v-html="item.desc" />
                </div>
            </div>
            <div v-else class="types m-b-16" :class="{ 'grid-col': displayGrid }">
                <div
                    @click="select(item)"
                    class="item"
                    :class="{ selected: item.$$selected }"
                    :key="item.title"
                    v-for="item in items"
                >
                    <div class="item-title f-medium" v-html="item.title" />
                    <div v-if="item.desc" class="f-12 m-t-7" v-html="item.desc" />
                    <div
                        v-if="item.desc2"
                        class="f-12 m-t-7"
                        v-html="item.desc2"
                        :style="{ color: item.$$selected ? 'red' : 'inherit' }"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import matchPreference from '@/assets/constants/match-preference'

export default {
    name: 'ModalEditStyleType',
    props: ['options'],
    data: () => ({
        items: [],
        checkInfo: false,
        income_types: [],
        asset_types: [],
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        displayGrid() {
            return ['religion'].includes(this.options.key)
        },
        multiSelect() {
            // if (this.options.isUsingManager === 'manager_active') {
            //     return ['religion', 'university', 'job_detail', 'marry_plan', 'wealth', 'school'].includes(
            //         this.options.key
            //     )
            // }
            return ['religion', 'university', 'job_detail', 'marry_plan', 'wealth', 'force', 'school'].includes(
                this.options.key
            )
        },
        getDescription() {
            if (this.options.key === 'wealth') {
                return '선택한 소득과 자산 중 하나를 만족하는 회원을 우선적으로 추천합니다.<br />다른 요소들을 고려해 선택한 범위를 벗어난 회원이 추천될 수 있습니다.'
            } else if (this.options.key === 'school') {
                return '선택한 학력의 회원을 우선적으로 추천합니다.<br />다른 요소들을 고려해 선택한 항목을 벗어난 회원이 추천될 수 있습니다.'
            } else if (this.options.key === 'job_detail') {
                return '선택한 커리어를 만족하는 회원을 우선적으로 추천합니다.<br />다른 요소들을 고려해 선택한 커리어를 벗어난 회원이 추천될 수 있습니다.'
            } else if (this.options.key === 'force') {
                if (this.options.isUsingManager === 'manager_active') {
                    return '매니저가 가장 중요하게 고려해주었으면 하는 것을 한 가지만 골라주세요. (여러 가지를 동시에 만족시키기 어려운 경우에 여기서 고른 항목을 우선순위로 매칭해드려요.) '
                }
                return '매니저가 꼭 반영해주었으면 하는 2가지를 선택해주세요.<br />(설정해주신 여러 조건을 모두 만족시키기 어려운 경우 여기서 고르신 2가지를 우선적으로 고려하여 프로필을 추천드립니다.)'
            } else if (this.options.key === 'religion') {
                return '선택한 종교를 만족하는 회원을 우선적으로 추천합니다.<br />다른 요소들을 고려해 선택한 종교를 벗어난 회원이 추천될 수 있습니다.'
            } else if (this.options.key === 'marry_plan') {
                return '선택한 결혼계획을 가진 회원을 우선적으로 추천합니다.<br />다른 요소들을 고려해 선택한 결혼계획을 벗어난 회원이 추천될 수 있습니다.'
            } else {
                return ''
            }
        },
        getDescriptionSecond() {
            if (this.options.key === 'wealth') {
                return '너무 좁게 설정할 경우 프로필 추천이 어려울 수 있으니, 외모, 키, 직업, 학력 등 다른 요소들이 마음에 들 경우를 가정해서 선택해주세요. '
            } else if (this.options.key === 'school') {
                return '너무 좁게 설정할 경우 프로필 추천이 어려울 수 있으니, 외모, 키, 직업, 경제력 등 다른 요소들이 마음에 들 경우를 가정해서 선택해주세요. '
            } else if (this.options.key === 'job_detail') {
                return '너무 좁게 설정할 경우 프로필 추천이 어려울 수 있으니, 외모, 키, 학력 등 다른 요소들이 마음에 들 경우를 가정해서 선택해주세요. '
            } else {
                return ''
            }
        },
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            const gender = this.me.gender === 0 ? 'male' : 'female'
            const types = matchPreference[this.options.key].filter(item => item.gender.includes(this.me.gender))
            const multiSelectIds = this.multiSelect
                ? ((this.me.style || {})[`${this.options.key}_type`] || []).map(item => item.value)
                : []

            if (this.options.key === 'wealth') {
                // if (multiSelectIds.length === 0) {
                //     multiSelectIds = [0, 10]
                // }
                this.items = types
                this.income_types = types
                    .filter(i => i.category === 'income')
                    .map(o => {
                        if (o.value === 0 && multiSelectIds.length === 0) {
                            this.$set(o, '$$selected', true)
                            return o
                        } else {
                            this.$set(o, '$$selected', multiSelectIds.includes(o.value))
                            return o
                        }
                    })

                this.asset_types = types
                    .filter(i => i.category === 'asset')
                    .map(o => {
                        if (o.value === 10 && multiSelectIds.filter(i => i >= 10).length === 0) {
                            this.$set(o, '$$selected', true)
                            return o
                        } else {
                            this.$set(o, '$$selected', multiSelectIds.includes(o.value))
                            return o
                        }
                    })
            } else {
                this.items = (this.options.key === 'height' ? types[gender] : types).map(o => {
                    if (this.multiSelect) {
                        if (o.value === 0 && multiSelectIds.length === 0) {
                            this.$set(o, '$$selected', true)
                            return o
                        } else {
                            this.$set(o, '$$selected', multiSelectIds.includes(o.value))
                            return o
                        }
                    } else {
                        // if (o.value === 0 && multiSelectIds.length === 0) {
                        //     this.$set(o, '$$selected', true)
                        //     return o
                        // } else {
                        this.$set(o, '$$selected', o.value === (this.me.style || {})[`${this.options.key}_type`])
                        return o
                        // }
                    }
                    // return o
                })
            }
        },
        select(item) {
            // 상관없음 선택 시 무조건반영 해제
            if (this.options.key === 'wealth' && item.value < 10) {
                // 소득
                const incomeAlreadySelectAll = this.income_types.some(item => item.value === 0 && item.$$selected)
                if (incomeAlreadySelectAll) {
                    this.income_types[0].$$selected = false
                }
                item.$$selected = !item.$$selected

                // 전체 선택 시 전부 괜찮아요로 수정
                const incomeSelectAllManually =
                    this.income_types.filter(item => item.$$selected).length === this.income_types.length - 1
                console.log('income', incomeSelectAllManually)
                if (incomeSelectAllManually || item.value === 0) {
                    this.income_types = this.income_types.map(o => ({
                        ...o,
                        $$selected: o.value === 0,
                    }))
                }
            } else if (this.options.key === 'wealth' && item.value >= 10) {
                // 자산
                const assetAlreadySelectAll = this.asset_types.some(item => item.value === 10 && item.$$selected)

                if (assetAlreadySelectAll) {
                    this.asset_types[0].$$selected = false
                }
                item.$$selected = !item.$$selected

                // 전체 선택 시 전부 괜찮아요로 수정
                const assetSelectAllManually =
                    this.asset_types.filter(item => item.$$selected).length === this.asset_types.length - 1
                if (assetSelectAllManually || item.value === 10) {
                    this.asset_types = this.asset_types.map(o => ({
                        ...o,
                        $$selected: o.value === 10,
                    }))
                }
            } else if (this.options.key === 'force') {
                if (this.options.isUsingManager === 'manager_active') {
                    this.items = this.items.map(o => ({
                        ...o,
                        $$selected: o.value === item.value,
                    }))
                } else {
                    if (this.items.filter(i => i.$$selected).length === 2 && !item.$$selected) {
                        this.$modal.basic({
                            body: `2가지 항목을 선택해주세요.`,
                            buttons: [
                                {
                                    label: 'CONFIRM',
                                    class: 'btn-primary',
                                },
                            ],
                        })
                    } else {
                        item.$$selected = !item.$$selected
                    }
                }
            } else if (!this.multiSelect || item.value === 0) {
                this.items = this.items.map(o => ({
                    ...o,
                    $$selected: o.value === item.value,
                }))
            } else {
                const alreadySelectAll = this.items.some(item => item.value === 0 && item.$$selected)
                if (alreadySelectAll) {
                    this.items[0].$$selected = false
                }
                item.$$selected = !item.$$selected

                // 전체 선택 시 전부 괜찮아요로 수정
                const selectAllManually = this.items.filter(item => item.$$selected).length === this.items.length - 1
                if (selectAllManually) {
                    this.items = this.items.map(o => ({
                        ...o,
                        $$selected: o.value === 0,
                    }))
                }
            }
        },
        submit() {
            if (this.options.key === 'wealth') {
                this.$emit('close', { items: [...this.income_types, ...this.asset_types] })
            } else {
                if (
                    this.options.key === 'force' &&
                    this.options.isUsingManager !== 'manager_active' &&
                    this.items.filter(i => i.$$selected).length === 1
                ) {
                    this.$modal.basic({
                        body: `2가지 항목을 선택해주세요.`,
                        buttons: [
                            {
                                label: 'CONFIRM',
                                class: 'btn-primary',
                            },
                        ],
                    })
                } else {
                    this.$emit('close', { items: this.items })
                }
            }
        },
    },
}
</script>
<style scoped lang="scss">
.modal-edit-style-type {
    overflow-y: hidden;
}
.body {
    padding: 30px 0;
    height: calc(100vh - 56px - 70px);
    overflow-y: auto;
}
.header {
    position: sticky;
    max-height: 52px;
    top: 0;
    background: #faf0e1;
    display: flex;
    // justify-content: flex-start;
    justify-content: space-between;
    align-items: center;
    // padding-left: 16px;
    // padding: 16px;
}
.edit-profile-complete {
    padding: 12px 16px;
    color: #151360;
    font-weight: 600;
    font-size: 15px;
}
.title {
    font-size: 18px;
    display: flex;
    // text-align: center;
    align-items: flex-end;
    color: #151360;
}
.grid-col {
    .item {
        @include center;
        margin: 0 !important;
    }
}
.item {
    // @include center;
    // text-align: center;
}
.description {
    line-height: 24px;
}
</style>
